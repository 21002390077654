<template>
  <div>
    <banner :path="bannerPath"></banner>
    <my-section title="他们都在优讯"
      desc="我们为一百多家银行提供专业的业务导入、信息系统建设和运营服务。(排名不分先后)">
      <banks slot="content" swiperId="js_successExample" :arrow="true"></banks>
    </my-section>
  </div>
</template>

<script>
import { banks, mySection, banner } from '@/components'
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css'
import bannerPath from '@/assets/images/example-banner.jpg'
export default {
  components: {
    banks,
    mySection,
    banner
  },
  data () {
    return {
      bannerPath
    }
  },
  mounted () {
    // let t = this
    // alert(t.IEVersion())
    const swiper = new Swiper('#js_mySwiper', {
      pagination: '.swiper-pagination',
      paginationClickable: true,
      loop: true,
      speed: 600,
      autoplay: 4000,
      observer:true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents:true,//修改swiper的父元素时，自动初始化swiper
      onTouchEnd: function() {
        // touchend的时候轮播图可能还在滑动，立即执行startAutoplay以后不起作用
        // 所以加个时间
        setTimeout(swiper.startAutoplay,600)
      }
    })

  },
  methods: {

    IEVersion () {
      var userAgent = navigator.userAgent 
      var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1  
      var isEdge = userAgent.indexOf("Edge") > -1 && !isIE
      var isIE11 = userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1
      if(isIE) {
        var reIE = new RegExp("MSIE (\\d+\\.\\d+);")
        reIE.test(userAgent)
        var fIEVersion = parseFloat(RegExp["$1"])
        return fIEVersion
      }
      if(isEdge) return 'edge'
      if(isIE11) return 11         // IE11  
      return -1                    // 不是ie浏览器
    }
  }
}
</script>

<style lang="scss">

</style>
